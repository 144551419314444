
import { Component, Prop, Vue } from "vue-property-decorator";
import settings from "@/config/settings";
import variables from "@/styles/variables.module.scss";
import { SettingsModule } from "@/store/modules/settings";

@Component({ name: "LayoutLogo" })
export default class extends Vue {
  @Prop({ required: true })
  public isCollapse!: boolean;
  @Prop({ default: "sideMenu" })
  public position!: string;

  public title = settings.title;

  get variables() {
    return variables;
  }

  get sideMenuTheme() {
    return SettingsModule.sideMenuTheme;
  }

  get backgroundColor() {
    if (this.position === "sideMenu") {
      return this.sideMenuTheme === "dark" ? variables.menuLogoDark : variables.menuLogoLight;
    }
  }
}
