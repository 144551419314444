
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import SideMenuItemLink from "./SideMenuItemLink.vue";
import { getTitle } from "@/utils/layout";

@Component({
  name: "SideMenuItem", // 解决 Vercel 打包后递归组件失效
  components: { SideMenuItemLink },
})
export default class extends Vue {
  @Prop({ required: true })
  public menuItem!: RouteConfig;
  @Prop({ default: false })
  public isCollapse!: boolean;
  @Prop({ default: true })
  public isFirstMenu!: boolean; // 是否是一级菜单

  public getTitle(menuItem: RouteConfig) {
    return getTitle(menuItem, this);
  }
}
