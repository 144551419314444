
import { MessageModule } from "@/store/modules/message";
import { SettingsModule } from "@/store/modules/settings";
import { UserModule } from "@/store/modules/user";
import { Component, Vue } from "vue-property-decorator";
import GlobalSettings from "./GlobalSettings.vue";

@Component({ name: "User", components: { GlobalSettings } })
export default class extends Vue {
  get user() {
    return UserModule.userInfo;
  }

  get showSettings() {
    return SettingsModule.showSettings;
  }

  get profileLabel() {
    let profile = this.$t("_headerBar.profile");
    return profile === "_headerBar.profile" ? "我的主页" : profile;
  }

  get messageCenterLabel() {
    let messageCenter = this.$t("_headerBar.messageCenter");
    return messageCenter === "_headerBar.messageCenter" ? "我的消息" : messageCenter;
  }

  get settingsLabel() {
    let settings = this.$t("_headerBar.settings");
    return settings === "_headerBar.settings" ? "我的设置" : settings;
  }

  get logOutLabel() {
    let logOut = this.$t("_headerBar.logOut");
    return logOut === "_headerBar.logOut" ? "退出登录" : logOut;
  }

  get messageCount() {
    // TODO：unreadList 是空的，因为没有请求数据，只有打开我的消息才会请求数据，这里应该请求 API 未读消息的数量
    return MessageModule.message.unreadList.length;
  }

  public openSettingsDrawer() {
    (this.$refs.globalSettings as any).openSettingsDrawer();
  }

  public async logout() {
    await UserModule.LogOut();
    // this.$router.push(`/login?redirect=${this.$route.fullPath}`).catch(err => {
    //   console.warn(err)
    // })
  }
}
