
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "ErrorLog" })
export default class extends Vue {
  @Prop({ default: 0 })
  public errorCount!: number;
  // 获取错误日志的数量
  get count() {
    return this.errorCount === 0 ? 0 : this.errorCount;
  }
  // 打开错误日志页面
  public openErrorLogger() {
    if (this.$route.name !== "ErrorLogger") {
      this.$router.push({
        name: "ErrorLogger",
      });
    }
  }
}
