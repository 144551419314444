/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'size': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M.56 438.936h437.889v146.128h-145.96v438.376H146.52V585.064H.56V438.936zm1021.737-292.248H735.236v876.752H579.574V146.688H292.512V.56h729.81l-.025 146.128z"/>'
  }
})
