
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "SideMenuTrigger" })
export default class extends Vue {
  @Prop({ default: false })
  public isCollapse!: boolean;

  @Emit()
  public toggleTrigger() {
    return;
  }
}
