/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'github': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M950.93 512q0 143.433-83.748 257.975T650.898 928.549q-15.433 2.852-22.6-4.023t-7.169-17.116V786.87q0-55.441-29.696-81.115 32.549-3.437 58.588-10.313t53.686-22.308 46.3-38.035 30.281-59.977 11.703-86.016q0-69.12-45.13-117.687 21.139-52.004-4.534-116.59-16.018-5.12-46.3 6.291t-52.59 25.161l-21.723 13.678Q568.54 285.11 512 285.11t-109.714 14.848q-9.143-6.29-24.284-15.433t-47.689-22.016-49.152-7.68Q256 319.415 277.138 371.42q-45.129 48.566-45.129 117.686 0 48.567 11.703 85.724t29.989 59.977 46.006 38.254 53.687 22.308 58.588 10.313q-22.82 20.553-28.014 58.88-11.995 5.705-25.746 8.558t-32.549 2.853-37.449-12.288-31.744-35.694q-10.825-18.286-27.721-29.696t-28.306-13.678l-11.41-1.682q-11.996 0-16.604 2.56t-2.853 6.583 5.12 7.972 7.46 6.876l4.024 2.852q12.58 5.705 24.868 21.724t17.993 29.11l5.706 13.166q7.46 21.724 25.16 35.109t38.254 17.115 39.717 4.023 31.744-1.975l13.166-2.267q0 21.723.292 50.834t.293 30.866q0 10.314-7.46 17.116t-22.821 4.023Q240.567 884.59 156.818 770.048T73.07 512.073q0-119.442 58.88-220.306t159.744-159.744T512 73.143t220.306 58.88T892.05 291.767t58.88 220.306z"/>'
  }
})
