
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "LangSelect" })
export default class extends Vue {
  @Prop({ default: "zh-CN" })
  public language!: string;

  public localList = [
    { label: "中文简体", value: "zh-CN" },
    { label: "中文繁体", value: "zh-TW" },
    { label: "English", value: "en-US" },
  ];

  @Emit("on-change")
  public handleSetLanguage(lang: string) {
    return lang;
  }
}
