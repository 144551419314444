var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"layout-logo-container",class:{
    'logo-collapse': _vm.isCollapse,
    'header-logo': _vm.position === 'header',
    'side-menu-logo': _vm.position === 'sideMenu',
  },style:({
    backgroundColor: _vm.backgroundColor,
  })},[_c('transition',{attrs:{"name":"layoutLogoFade"}},[(_vm.isCollapse)?_c('router-link',{key:"collapse",staticClass:"layout-logo-link",attrs:{"to":"/"}},[_c('img',{staticClass:"layout-logo-img",attrs:{"src":require("@/assets/logo.png")}})]):_c('router-link',{key:"expand",staticClass:"layout-logo-link",attrs:{"to":"/"}},[_c('img',{staticClass:"layout-logo-img",attrs:{"src":require("@/assets/logo.png")}}),_c('h1',{staticClass:"layout-logo-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }