/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tick': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M911.019 181.973a64 64 0 0116.341 89.046L500.693 889.685a64 64 0 01-99.584 7.211L123.776 598.229a64 64 0 0193.781-87.125L440.62 751.317l381.354-552.96a64 64 0 0189.046-16.384z"/>'
  }
})
