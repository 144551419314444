
import { LayoutModule } from "@/store/modules/layout";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "MainContent" })
export default class extends Vue {
  // 获取缓存的组件名
  get cachedTagList() {
    return LayoutModule.tagsNav.cachedTagList;
  }
  get key() {
    return this.$route.path || this.$route.name;
  }
}
