/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setting': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M550.4 74.667c25.6 0 46.933 19.2 53.333 44.8l14.934 85.333 38.4 17.067 70.4-51.2c19.2-14.934 46.933-12.8 66.133 4.266l2.133 2.134 53.334 53.333c19.2 19.2 21.333 46.933 6.4 68.267l-49.067 70.4 17.067 38.4L908.8 422.4c23.467 4.267 42.667 25.6 44.8 49.067V550.4c0 25.6-19.2 46.933-44.8 53.333l-85.333 14.934-17.067 38.4 49.067 70.4c14.933 19.2 12.8 46.933-4.267 66.133l-2.133 2.133-53.334 53.334c-19.2 19.2-46.933 21.333-68.266 6.4l-70.4-49.067-38.4 17.067-14.934 85.333c-4.266 23.467-25.6 42.667-49.066 44.8h-78.934c-25.6 0-46.933-19.2-53.333-44.8l-14.933-85.333-38.4-17.067-72.534 46.933c-19.2 14.934-46.933 12.8-66.133-4.266l-2.133-2.134-53.334-53.333c-19.2-19.2-21.333-46.933-6.4-68.267l49.067-70.4-17.067-38.4L115.2 601.6c-23.467-4.267-42.667-25.6-44.8-49.067V473.6c0-25.6 19.2-46.933 44.8-53.333l85.333-14.934 17.067-38.4-46.933-70.4c-14.934-19.2-12.8-46.933 2.133-64l2.133-2.133 53.334-53.333c19.2-19.2 46.933-21.334 68.266-6.4l70.4 49.066 38.4-17.066 14.934-85.334c4.266-23.466 25.6-42.666 49.066-44.8H550.4zm-38.4 320C448 394.667 394.667 448 394.667 512S448 629.333 512 629.333 629.333 576 629.333 512 576 394.667 512 394.667z"/>'
  }
})
