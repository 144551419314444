
import { compile } from "path-to-regexp";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getTitle } from "@/utils/layout";
import { Breadcrumbs } from "../index.vue";

@Component({ name: "Breadcrumb" })
export default class extends Vue {
  @Prop({ required: true })
  public breadcrumbs!: Breadcrumbs[];
  // 获取展示的 title
  public getTitle(breadcrumbItem: Breadcrumbs) {
    return getTitle(breadcrumbItem, this);
  }
  // 点击面包屑跳转
  public handleLink(item: Breadcrumbs) {
    const { redirect, fullPath } = item;
    // 如果是 redirect
    if (this.$route.fullPath !== redirect) {
      if (redirect) {
        this.$router.push(redirect as string).catch(err => {
          console.warn(err);
        });
        return;
      }
      if (fullPath.indexOf("?") === -1) {
        // 这里的面包屑地址以 fullPath 跳转（fullPath 已经保存在面包屑里），你也可以和通过 name 跳转
        this.$router.push(this.pathCompile(fullPath)).catch(err => {
          console.warn(err);
        });
      } else {
        let f = fullPath.split("?");
        this.$router.push(this.pathCompile(f[0]) + "?" + f[1]).catch(err => {
          console.warn(err);
        });
      }
    }
  }

  // 如果路由 url 是 /user/:id/:name，参数 data 为 {id: 10001, name: 'bob'}，则解析成 /user/10001/bob，格式：compile(url)(data)
  private pathCompile(path: string) {
    const { params } = this.$route;
    const toPath = compile(path);
    return toPath(params);
  }
}
