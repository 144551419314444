
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "SizeSelect" })
export default class extends Vue {
  @Prop({ default: "medium" })
  public size!: string;

  public sizeOptions = [
    { label: "Large", value: "large" },
    { label: "Medium", value: "medium" },
    { label: "Small", value: "small" },
    { label: "Mini", value: "mini" },
  ];

  @Emit("on-change")
  public handleSetSize(size: string) {
    return size;
  }
}
