/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'layout-one': {
    width: 52,
    height: 45,
    viewBox: '0 0 52 45',
    data: '<defs><filter x="-9.4%" y="-6.2%" width="118.8%" height="122.5%" filterUnits="objectBoundingBox" id="svgicon_layout-one_a"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter><filter x="-4.2%" y="-2.5%" width="108.3%" height="110%" filterUnits="objectBoundingBox" id="svgicon_layout-one_c"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowBlurOuter1"/></filter><rect pid="0" id="svgicon_layout-one_b" x="0" y="0" width="48" height="40" rx="4"/></defs><g filter="url(#svgicon_layout-one_a)" transform="translate(2 1)" _fill="none" fill-rule="evenodd"><mask id="svgicon_layout-one_d" _fill="#fff"><use xlink:href="#svgicon_layout-one_b"/></mask><use _fill="#000" filter="url(#svgicon_layout-one_c)" xlink:href="#svgicon_layout-one_b"/><use _fill="#F0F2F5" xlink:href="#svgicon_layout-one_b"/><path pid="1" _fill="#FFF" mask="url(#svgicon_layout-one_d)" d="M0 0h48v10H0z"/><path pid="2" _fill="#99a9bf" mask="url(#svgicon_layout-one_d)" d="M0 0h16v40H0z"/></g>'
  }
})
