
import { Component, Prop, Vue } from "vue-property-decorator";
import ThemePicker from "@/components/ThemePicker/index.vue";
import { SettingsModule } from "@/store/modules/settings";
import { setTitle } from "@/utils/layout";

@Component({ name: "GlobalSettings", components: { ThemePicker } })
export default class extends Vue {
  @Prop({ default: false })
  public showSvg!: boolean;
  public drawerVisible = false;

  get titleModeOptions() {
    return [
      {
        value: "0",
        label: this.$t("_settings.titleModeOne"),
      },
      {
        value: "1",
        label: this.$t("_settings.titleModeTwo"),
      },
      {
        value: "2",
        label: this.$t("_settings.titleModeThree"),
      },
      {
        value: "3",
        label: this.$t("_settings.titleModeFour"),
      },
    ];
  }

  get theme() {
    return SettingsModule.theme;
  }

  get showBreadcrumb() {
    return SettingsModule.showBreadcrumb;
  }

  set showBreadcrumb(value) {
    SettingsModule.changeSetting({ key: "showBreadcrumb", value });
  }

  get showTagsNav() {
    return SettingsModule.showTagsNav;
  }

  set showTagsNav(value) {
    SettingsModule.changeSetting({ key: "showTagsNav", value });
  }

  get recordTagsNav() {
    return SettingsModule.recordTagsNav;
  }

  set recordTagsNav(value) {
    SettingsModule.changeSetting({ key: "recordTagsNav", value });
  }

  get showLayoutLogo() {
    return SettingsModule.showLayoutLogo;
  }

  set showLayoutLogo(value) {
    SettingsModule.changeSetting({ key: "showLayoutLogo", value });
  }

  get sideMenuTheme() {
    return SettingsModule.sideMenuTheme;
  }

  get titleMode() {
    return SettingsModule.titleMode;
  }

  set titleMode(value) {
    SettingsModule.changeSetting({ key: "titleMode", value });
    // 根据选择的标题模式，重新渲染浏览器标题
    setTitle(this.$route, this);
  }

  get layoutMode() {
    return SettingsModule.layoutMode;
  }

  public handleSideMenuTheme(value: string) {
    SettingsModule.changeSetting({ key: "sideMenuTheme", value });
  }

  public handleLayoutMode(value: number) {
    SettingsModule.changeSetting({ key: "layoutMode", value });
    this.$nextTick(() => {
      window.location.reload();
    });
  }

  // 打开设置窗口
  public openSettingsDrawer() {
    this.drawerVisible = true;
  }

  // 选择主题色回调
  public themeChange(value: string) {
    SettingsModule.changeSetting({ key: "theme", value });
  }
  // 重置配置回调
  public resetSettings() {
    let message = this.$t("_settings.resetSettings");
    message = message === "_settings.resetSettings" ? "正在清除设置缓存并刷新，请稍候..." : message;
    this.$message({
      message: message as string,
      duration: 1000,
      iconClass: "el-icon-loading",
    });
    SettingsModule.resetSettings();
    setTimeout("window.location.reload()", 1000);
  }
}
